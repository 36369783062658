export const phrases = {
  your_party: "Твоя вечеринка.",
  all_pictures: "Все картинки.",
  forgot_password: "Забыл свой пароль?",
  no_account_yet: "У тебя еще нет аккаунта? Зарегистрируйся сейчас.",
  send_your_event: "Пригласи своих гостей",
  scan_here: "Сканируй здесь",
  show_more_replies: "Смотри больше ответов",
  shared_image_text: "Взгляни на фотографию, сделанную на одном из мероприятий с https://www.everlense.de.",
  image_not_available_anymore: "Это изображение больше не доступно",
  add_images: "Добавь изображения",
  capture_photo: "Сделай фото",
  enter_your_name: "Введите свое имя",
  your_photo_app_for_weddings: "Твое фотоприложение для свадебных торжеств",
  invite_guests: "Пригласи гостей",
  delete_demo_data: "Удали демонстрационные данные",
  event_description_placeholder: "Что еще ты хотел бы рассказать своим гостям? Перейди в настройки, чтобы настроить описание.",
  start_slideshow: "Начни слайд-шоу здесь",
  slideshow_steps_1: "Перейдите к своему устройству",
  slideshow_steps_2: "и введите следующее",
  slideshow_steps_3: "10-значный код",
  slideshow_steps_4: "в.",
  print_tasks: "Задания для печати",
  edit_tasks: "Управляй заданиями",
  challenge_tips_and_tricks_1: "Советы и хитрости для",
  challenge_tips_and_tricks_2: "Твой идеальный вызов",
  challenge_tips_and_tricks_content_1: "Распечатай свой шаблон на плотной бумаге.",
  challenge_tips_and_tricks_content_2: "Перед вечеринкой распредели задания на каждое место.",
  challenge_tips_and_tricks_content_3: "Расскажи о фотозадаче как можно большему количеству людей.",
  challenge_tips_and_tricks_content_4: "Используй живое слайд-шоу, чтобы все гости могли видеть снимки в режиме реального времени.",
  print_tasks_intro: "Чтобы твои гости могли найти задания для фотозадачи, вывеси их на вечеринке.",
  print_tasks_title_1: "Карточки для распечатки (популярные)",
  print_tasks_title_2: "Экспортируй только QR-коды (и разрабатывай карточки сам)",
  print_tasks_action_1: "Скачать PDF-файлы",
  print_tasks_action_2: "Скачать QR-коды",
  print_tasks_description_1: "Твои гости могут присоединиться к фотозадаче, используя QR-коды на наших распечатанных карточках.",
  print_tasks_description_2: "Загрузи QR-коды, чтобы начать фотозадание, и используй их для создания собственных открыток. На каждое задание дается один QR-код.",
  edit_tasks_title_1: "Выберите задания",
  edit_tasks_title_2: "для твоих гостей",
  really_want_to_delete_task: "Действительно удалить задание?",
  add_task_button: "Добавь задание",
  delete_all_tasks_button: "Удалить все",
  settings_event_name_header: "Как должно называться твое мероприятие?",
  settings_event_description_header: "Что еще нужно знать твоим гостям?",
  settings_event_name_label: "Название мероприятия",
  settings_event_description_label: "Описание события",
  settings_password_title: "Изменить пароль",
  settings_old_password_header: "Старый пароль",
  settings_new_password_header: "Новый пароль",
  settings_new_password_repeat_header: "Повторите новый пароль",
  settings_invite_admin_title: "Добавьте администраторов",
  settings_invite_admin_header: "Адрес электронной почты нового администратора",
  send_invite: "Отправь приглашение",
  settings_deactivate_challenge_title: "Деактивировать вызов",
  settings_deactivate_challenge_header: "Активировать/деактивировать вызов",
  settings_delete_data_title: "Удалить аккаунт",
  settings_delete_data_header: "Постоянно удаляй все данные",
  delete_event: "Удалить событие",
  delete_event_toast_content: "Нажми \"Удалить\", чтобы навсегда закрыть свой аккаунт. ВНИМАНИЕ: Все загруженные изображения, видео и данные после этого безвозвратно теряются и уже не могут быть восстановлены даже командой everlense.",
  settings_legal: "Юридические вопросы",
  settings_legal_content_1: "Создав свое мероприятие, ты получишь",
  settings_legal_content_2: "GTC",
  settings_legal_content_3: " и наш",
  settings_legal_content_4: "Политика конфиденциальности ",
  settings_legal_content_5: "Принято. Если ты хочешь узнать больше о everlense, посети наш сайт ",
  settings_legal_content_6: "Оттиск.",
  reset_password: "Сбросить пароль",
  register_phrase: "Нам нужен твой адрес электронной почты, чтобы завершить настройку твоего мероприятия.",
  register_phrase_booked: "Бронирование прошло успешно! Нам нужен твой адрес электронной почты, чтобы завершить настройку твоего мероприятия.",
  email_address: "Адрес электронной почты",
  register_legal: "Присоединяясь, ты принимаешь наши <a class=\"underline\" href=\"https://www.everlense.de/agb\">правила и условия</a>, а также <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">политику конфиденциальности</a>. Если ты хочешь узнать больше о everlense, посети наше <a class=\"underline\" href=\"https://www.everlense.de/impressum\">юридическое уведомление</a>. Подписываясь, ты также соглашаешься получать от нас маркетинговые письма. Ты можешь отозвать это согласие в любой момент.",
  install_everlense: "Установи эверленс",
  install_content_1: "Установи everlense на свой домашний экран, чтобы никогда не забывать о событиях.",
  install_content_2: "Нажми",
  install_content_3: "Затем \"Перейти на домашний экран\".",
  install_content_4: "Позже",
  install_content_5: "Отлично!",
  no_images_yet: "Еще не было загружено ни одной картинки. Нажми на кнопку с плюсом, чтобы загрузить картинку.",
  delete_all_tasks_really: "Действительно удалить все задания?",
  your_task: "Твоя задача",
  change_colors: "Измените цвета",
  primary_color: "Основной цвет",
  background_color: "Цвет фона",
  reset: "Перезагрузка",
  choose_color: "Выберите цвет",
  upgrade_package: "Пакет обновления",
  upgrade_required: "Требуется обновление",
  current_package: "Текущий пакет",
  used_image_count: "{used} {max} изображений.",
  deleted_image_count: "{not_deleted} {deleted} находящиеся в твоей галерее, удалены.",
  used_downloads_count: "{used} {max} используется при массовых загрузках.",
  most_popular: "Самый популярный",
  price: "{price} Евро",
  max_media: "{count} максимум изображений",
  max_guests: "{count} максимум. Гости",
  infinite_guests: "Неограниченное количество гостей",
  storage_duration: "{duration} Месяцы Срок хранения",
  max_bulk_downloads: "Бесплатные массовые загрузки | бесплатные массовые загрузки",
  custom_colors: "Индивидуальные цвета",
  multiple_admins: "Несколько администраторов",
  whatsapp_support: "Премиальная поддержка WhatsApp",
  go_to_checkout: "К кассе",
  start_setup: "Начни настройку",
  upgrade_toast_content: "Чтобы иметь возможность пользоваться этой функцией, ты должен обновить свой пакет everlense. Посмотри на различные варианты. ",
  no_thank_you: "Нет, спасибо.",
  i_want_to_know_more: "Узнай больше",
  demo_event_title: "Лиза и Ян",
  how_do_you_want_to_reedem: "Как бы ты хотел выкупить свою посылку?",
  currently_logged_in_event: "{name} В данный момент ты вошел на мероприятие: Хочешь применить свою покупку к этому событию?",
  choose_event: "К какому событию ты хотел бы применить свою покупку?",
  use_for_current_event: "текущее событие",
  use_for_existing_event: "существующее событие",
  use_for_new_event: "новое событие",
  settings_event_date_header: "Когда состоится твое мероприятие?",
  settings_event_date_label: "Дата проведения мероприятия",
  sort: "Сортировка",
  time_of_recording: "Время записи",
  time_of_upload: "Время загрузки",
  lets_setup_event: "Давай организуем твое мероприятие вместе",
  we_will_help_you_setup: "Мы поможем тебе организовать твое эверленс мероприятие всего за 2 минуты. Тебе нравится?",
  what_is_the_name: "Как должно называться твое мероприятие?",
  cancel_setup: "Отменить настройку",
  enter_event_name_here: "Введите название мероприятия здесь",
  when_does_event_take_place: "Когда состоится твое мероприятие?",
  enter_event_date_here: "Укажите здесь дату события",
  nice_please_upload_photo: "Очень мило. Загрузи фотографию с мероприятия, чтобы сделать его еще более персонализированным.",
  select_photo: "Выберите фотографию",
  what_do_you_want_to_describe: "Вот это да! Что-нибудь еще ты хотел бы рассказать своим гостям?",
  enter_description_here: "Введите описание здесь",
  finish_setup: "Завершите настройку",
  successfull_save: "Успешно сохранено",
  successfull_deletion: "Успешно удалено",
  copied_link: "Ссылка скопирована",
  booking_successfull: "Бронирование прошло успешно",
  completed_setup: "Установка завершена",
  sent_invite: "Приглашение отправлено",
  initiated_payout: "Созданная выплата",
  sent_email: "Электронная почта отправлена",
  max_ten_images: "Не более 10 изображений одновременно.",
  reached_download_limit: "Лимит загрузки события достигнут.",
  reached_mass_download_limit: "Максимальное количество массовых загрузок достигнуто.",
  no_images_were_uploaded: "Пока не загружено ни одной фотографии.",
  booking_failed: "Бронирование не удалось.",
  package_already_retrieved: "Пакет уже выкуплен",
  download_all_pictures: "Скачать все изображения",
  notice: "Подсказка",
  in_your_package: "В твоей посылке",
  bulk_download_modal_2: "всех фотографий сразу. Если ты сейчас нажмешь на кнопку \"Загрузить\", то получишь",
  bulk_download_modal_3: "один из них",
  bulk_download_modal_4: "это",
  bulk_download_modal_5: "скачать",
  bulk_download_modal_6: "Ты израсходовал все объемные загрузки из своего пакета. Пожалуйста, закажи обновление, чтобы загрузить все изображения.",
  bulk_download_modal_7: "Пожалуйста, подожди. Твои изображения готовятся к загрузке.",
  donwload_modal_1: '',
  donwload_modal_2: "действительно скачать?",
  delete_modal_1: '',
  delete_modal_2: "действительно удалить?",
  download_preparation: "Твоя загрузка находится в стадии подготовки. Не позднее чем через 15 минут ты получишь письмо с персональной ссылкой для скачивания.",
  save_pictures: "Сохраняйте изображения",
  delete_pictures: "Удалить изображения",
  upload_in_progress: "Загрузите бег",
  change_language: "Измените язык",
  change_language_info: "Мы разработали everlense так, что язык автоматически подстраивается под язык браузера на устройствах твоих гостей. Так что если на твоей свадьбе будут иностранные гости, это не проблема, и фотогалерея всегда будет понятна всем.",
  multiple_languages_1: "Многоязычие",
  multiple_languages_2: "(английский и немецкий)",
  okay: "Отлично!",
  download_pictures: "Скачать изображения",
  max_ten_images_go_to_settings: "Допускается не более 10 изображений. Перейди в настройки, чтобы загрузить все изображения.",
  your_images_are_ready: "Ура, твои фотографии готовы!",
  here_are_your_images: "{event}Вот фотографии с твоего мероприятия. Веселитесь!",
  ps_mass_download: "PS: Просто скачивай части одну за другой. Это будет засчитано только как массовое скачивание.",
  download_here: "Скачать можно здесь",
  download_part: "{part} Часть загрузки",
  switch_language: "Измените язык",
  tasks_choose_language: "На каком языке должны быть составлены примерные задания?",
  successfull_task_recreation: "Язык примеров заданий был изменен",
  something_went_wrong: "Что-то пошло не так",
  a_small_present_for_you: "Небольшой подарок для тебя!",
  present_description: "Хочешь использовать everlense на своем мероприятии? <span class=\"font-medium\">Тогда у нас есть для тебя сюрприз</span>. Мы отправим его на твой электронный адрес.",
  your_email_address: "Твой адрес электронной почты",
  save_discount: "Отправка",
  accept_agb_and_mail: "Я согласен с <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">правилами и условиями</a> и хочу получать электронные письма от everlense.",
  thank_you_for_sending: "Мы отправим тебе письмо с твоим персональным сюрпризом в течение ближайших 15 минут.",
  discount_saved: "Сюрприз обеспечен!",
  was_reported: "{type} Об этом сообщил один из гостей .",
  what_shall_happen: "{type} Что с этим должно произойти?",
  edit_reporting_description: "{type} Пожалуйста, проверь и реши, стоит ли его снова выпустить или удалить навсегда. Пока же мы скрыли изображение.",
  how_did_you_get_to_know_us: "Как ты узнал об эверленсе?",
  please_select: "Пожалуйста, выберите",
  friends_and_family: "Друзья и знакомые",
  seen_at_a_wedding: "Видели на свадьбе",
  other: "Разное",
  settings_deactive_challenge_text_download: "Маркировка изображений во время загрузки",
  your_payment_is_processing: "Твой платеж находится в процессе обработки. Пожалуйста, наберись терпения на минутку.",
  coupon_code: "Код ваучера"
};