export const words = {
  email: "Пиши на почту",
  Email: "Пиши на почту",
  password: "Пароль",
  login: "Войдите в систему",
  pictures: "Картинки",
  copy_link: "Копировать ссылку",
  share_with_whatsapp: "Через WhatsApp",
  qrdownload: "Qr-код скачать",
  flyerdownload: "Скачать флаер",
  logout: "Выйти из системы",
  challenge: "Фотовызов",
  preparation: "Подготовка",
  photos: "Фотографии",
  challenges: "Вызов",
  diashow: "Живое слайд-шоу",
  settings: "Настройки",
  of: "с сайта",
  comments: "Комментарии",
  liked: "Например, .",
  answer: "Ответы",
  minutes: " минут",
  hours: " Часы",
  days: " Дни",
  yesterday: "Вчера",
  hide: "Скрыть",
  share: "Поделиться",
  report: "Отчет",
  delete: "Удалить",
  description: "Описание",
  enter: "Присоединяйся к",
  install: "Установи",
  or: "или",
  event: "Мероприятие",
  save: "Сохрани",
  cancel: "Отмена",
  continue: "Далее",
  task: "Задание",
  take_photo: "Сделай фото",
  skip: "Скип",
  color: "Цвет",
  videos: "Видео",
  video: "Видео",
  eur: "Евро",
  usd: "Доллар",
  packages: {
    free: "Бесплатно",
    base: "База",
    upgrade_base_to_premium: "Премиум",
    upgrade_base_to_deluxe: "Делюкс",
    premium: "Премиум",
    upgrade_premium_to_deluxe: "Делюкс",
    deluxe: "Делюкс"
  },
  1: "A",
  2: "Два",
  3: "Три",
  4: "Четыре",
  5: "Пять",
  download: "Скачать",
  are: "это",
  is: "это",
  mass_download: "Массовая загрузка",
  upgrade: "Обновление",
  start_now: "Начни",
  live_diashow: "Живое слайд-шоу",
  code: "Код",
  chosen: "избранное",
  all: "Все",
  picture: "Картинка",
  language: "Язык",
  languages: {
    de: "Немецкий",
    en: "Английский",
    fr: "Французский",
    it: "Итальянский",
    es: "Испанский"
  },
  close: "Закрыть",
  edit: "Редактировать",
  release: "Выпуск",
  check: "Проверь"
};