import { createI18n } from 'vue-i18n'
import { translations as DE } from './translations/de/translations'
import { translations as EN } from './translations/en/translations'
import { translations as FR } from './translations/fr/translations'
import { translations as IT } from './translations/it/translations'
import { translations as NL } from './translations/nl/translations'
import { translations as HR } from './translations/hr/translations'
import { translations as PT } from './translations/pt/translations'
import { translations as ES } from './translations/es/translations'
import { translations as TR } from './translations/tr/translations'
import { translations as EL } from './translations/el/translations'
import { translations as RU } from './translations/ru/translations'

export type TLanguageCodes =
    | 'de'
    | 'en'
    | 'fr'
    | 'it'
    | 'hr'
    | 'nl'
    | 'pt'
    | 'es'
    | 'tr'
    | 'el'
    | 'ru'

export function isValidLanguageCode(code: string): code is TLanguageCodes {
    return [
        'de',
        'en',
        'fr',
        'it',
        'hr',
        'nl',
        'pt',
        'es',
        'tr',
        'el',
        'ru',
    ].includes(code as TLanguageCodes)
}
export const translations = {
    de: DE,
    en: EN,
    fr: FR,
    it: IT,
    hr: HR,
    nl: NL,
    pt: PT,
    es: ES,
    tr: TR,
    el: EL,
    ru: RU,
}

export const languageNames = {
    de: 'Deutsch',
    en: 'English',
    fr: 'French',
    it: 'Italian',
    nl: 'Dutch',
    hr: 'Croatian',
    pt: 'Portuguese',
    es: 'Spanish',
    tr: 'Turkish',
    el: 'Greek',
    ru: 'Russian',
}

export const i18nInstance = createI18n({
    locale: window.navigator.language,
    legacy: false,
    fallbackLocale: 'de',
    messages: translations,
    missingWarn: false,
    fallbackWarn: false,
})

export const i18n = i18nInstance.global
