export const phrases = {
  your_party: "Senin partin.",
  all_pictures: "Tüm resimler.",
  forgot_password: "Şifrenizi mi unuttunuz?",
  no_account_yet: "Henüz bir hesabınız yok mu? Şimdi kaydolun.",
  send_your_event: "Misafirlerinizi davet edin",
  scan_here: "Burayı tarayın",
  show_more_replies: "daha fazla cevap gör",
  shared_image_text: "https://www.everlense.de ile bir etkinlikte çekilen fotoğrafa bir göz atın",
  image_not_available_anymore: "Bu resim artık mevcut değil",
  add_images: "Resim ekleme",
  capture_photo: "Bir fotoğraf çekin",
  enter_your_name: "Adınızı girin",
  your_photo_app_for_weddings: "Düğünler için fotoğraf uygulamanız",
  invite_guests: "Konukları davet edin",
  delete_demo_data: "Demo verilerini silme",
  event_description_placeholder: "Misafirlerinize başka ne söylemek istersiniz? Açıklamayı özelleştirmek için ayarlara gidin.",
  start_slideshow: "Slayt gösterisini buradan başlatın",
  slideshow_steps_1: "Cihazınızla şuraya gidin",
  slideshow_steps_2: "ve aşağıdakileri girin",
  slideshow_steps_3: "10 haneli kod",
  slideshow_steps_4: "içinde.",
  print_tasks: "Yazdırma görevleri",
  edit_tasks: "Görevleri yönetin",
  challenge_tips_and_tricks_1: "Şunlar için ipuçları ve püf noktaları",
  challenge_tips_and_tricks_2: "Mükemmel meydan okumanız",
  challenge_tips_and_tricks_content_1: "Şablonunuzu kalın bir kağıda yazdırın.",
  challenge_tips_and_tricks_content_2: "Partinizden önce görevleri her koltuğa dağıtın.",
  challenge_tips_and_tricks_content_3: "Fotoğraf yarışması hakkında mümkün olduğunca çok kişiye bilgi verin.",
  challenge_tips_and_tricks_content_4: "Tüm konukların anlık görüntüleri gerçek zamanlı olarak görebilmesi için canlı slayt gösterisini kullanın.",
  print_tasks_intro: "Konuklarınızın fotoğraf yarışmasının görevlerini bulabilmelerini sağlamak için bunları partide sergilemelisiniz.",
  print_tasks_title_1: "Çıktısı alınacak kartlar (popüler)",
  print_tasks_title_2: "Yalnızca QR kodlarını dışa aktarın (ve kartları kendiniz tasarlayın)",
  print_tasks_action_1: "PDF'leri İndirin",
  print_tasks_action_2: "QR kodlarını indirin",
  print_tasks_description_1: "Misafirleriniz yazdırılabilir kartlarımızdaki QR kodlarını kullanarak fotoğraf yarışmasına katılabilirler.",
  print_tasks_description_2: "Fotoğraf yarışmasını başlatmak için QR kodlarını indirin ve bunları kendi kartlarınızı tasarlamak için kullanın. Her görev için bir QR kodu bulunmaktadır.",
  edit_tasks_title_1: "Görevleri seçin",
  edit_tasks_title_2: "misafirleriniz için",
  really_want_to_delete_task: "Görevi gerçekten silecek misiniz?",
  add_task_button: "Görev ekle",
  delete_all_tasks_button: "Tümünü sil",
  settings_event_name_header: "Etkinliğinizin adı ne olmalı?",
  settings_event_description_header: "Misafirlerinizin başka neleri bilmesi gerekiyor?",
  settings_event_name_label: "Etkinliğin adı",
  settings_event_description_label: "Etkinliğin açıklaması",
  settings_password_title: "Şifre değiştirme",
  settings_old_password_header: "Eski şifre",
  settings_new_password_header: "Yeni şifre",
  settings_new_password_repeat_header: "Yeni şifreyi tekrarla",
  settings_invite_admin_title: "Yönetici ekleme",
  settings_invite_admin_header: "Yeni yöneticinin e-posta adresi",
  send_invite: "Davetiye gönder",
  settings_deactivate_challenge_title: "Mücadeleyi devre dışı bırak",
  settings_deactivate_challenge_header: "Mücadeleyi etkinleştirin/devre dışı bırakın",
  settings_delete_data_title: "Hesap silme",
  settings_delete_data_header: "Tüm verileri kalıcı olarak silme",
  delete_event: "Etkinliği sil",
  delete_event_toast_content: "Hesabınızı kalıcı olarak kapatmak için \"Sil \"e tıklayın. DİKKAT: Yüklenen tüm resimler, videolar ve veriler geri alınamaz bir şekilde kaybolur ve everlense ekibi tarafından bile artık geri yüklenemez.",
  settings_legal: "Yasal konular",
  settings_legal_content_1: "Etkinliğinizin oluşturulmasıyla birlikte",
  settings_legal_content_2: "GTC",
  settings_legal_content_3: " ve bizim",
  settings_legal_content_4: "Gizlilik Politikası ",
  settings_legal_content_5: "kabul edildi. everlense hakkında daha fazla bilgi edinmek isterseniz ",
  settings_legal_content_6: "Baskı.",
  reset_password: "Şifreyi sıfırla",
  register_phrase: "Etkinliğinizin kurulumunu tamamlamak için e-posta adresinize ihtiyacımız var.",
  register_phrase_booked: "Rezervasyon başarılı oldu! Etkinliğinizin kurulumunu tamamlamak için e-posta adresinize ihtiyacımız var.",
  email_address: "E-posta adresi",
  register_legal: "Katılarak, <a class=\"underline\" href=\"https://www.everlense.de/agb\">hüküm ve koşullarımızı ve</a> <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">gizlilik politikamızı</a> kabul etmiş olursunuz. everlense hakkında daha fazla bilgi <a class=\"underline\" href=\"https://www.everlense.de/impressum\">edinmek</a> isterseniz, <a class=\"underline\" href=\"https://www.everlense.de/impressum\">yasal bildirimimizi</a> ziyaret edin. Kaydolarak, bizden pazarlama e-postaları almayı da kabul etmiş olursunuz. Bu izni istediğiniz zaman iptal edebilirsiniz.",
  install_everlense: "Everlense'i kurun",
  install_content_1: "Etkinliği asla unutmamak için everlense'i ana ekranınıza yükleyin.",
  install_content_2: "Tıklayın",
  install_content_3: "ve ardından \"Ana ekrana git\".",
  install_content_4: "Daha sonra",
  install_content_5: "Pekala!",
  no_images_yet: "Henüz hiçbir resim yüklenmemiştir. Bir resim yüklemek için artı düğmesine basın.",
  delete_all_tasks_really: "Gerçekten tüm görevleri siliyor musunuz?",
  your_task: "Sizin göreviniz",
  change_colors: "Renkleri değiştir",
  primary_color: "Ana renk",
  background_color: "Arka plan rengi",
  reset: "Sıfırla",
  choose_color: "Renk seçiniz",
  upgrade_package: "Yükseltme paketi",
  upgrade_required: "Yükseltme gerekli",
  current_package: "Güncel paket",
  used_image_count: "{used} {max} görüntülerin.",
  deleted_image_count: "{not_deleted} {deleted} şu anda galerinizde, silindi.",
  used_downloads_count: "{used} {max} toplu indirmeler tarafından kullanılır.",
  most_popular: "En popüler",
  price: '{price} Euro',
  max_media: "{count} maks. görüntüler",
  max_guests: "{count} maks. Misafirler",
  infinite_guests: "Sınırsız sayıda misafir",
  storage_duration: "{duration} Ay Depolama süresi",
  max_bulk_downloads: "ücretsiz toplu indirme | ücretsiz toplu indirmeler",
  custom_colors: "Bireysel renkler",
  multiple_admins: "Birden fazla yönetici",
  whatsapp_support: "Premium WhatsApp Desteği",
  go_to_checkout: "Kasaya",
  start_setup: "Kurulumu başlat",
  upgrade_toast_content: "Bu işlevi kullanabilmek için everlense paketinizi yükseltmeniz gerekir. Çeşitli seçeneklere bir göz atın. ",
  no_thank_you: "Hayır, teşekkür ederim.",
  i_want_to_know_more: "Daha fazla bilgi edinin",
  demo_event_title: 'Lisa & Jan',
  how_do_you_want_to_reedem: "Paketinizi nasıl kullanmak istersiniz?",
  currently_logged_in_event: "{name} Şu anda etkinliğe giriş yapmış durumdasınız: Satın aldığınız ürünü bu etkinliğe uygulamak ister misiniz?",
  choose_event: "Satın aldığınız ürünü hangi etkinliğe uygulamak istersiniz?",
  use_for_current_event: "güncel olay",
  use_for_existing_event: "mevcut etkinlik",
  use_for_new_event: "yeni etkinlik",
  settings_event_date_header: "Etkinliğiniz ne zaman gerçekleşecek?",
  settings_event_date_label: "Etkinlik tarihi",
  sort: "Sırala",
  time_of_recording: "Kayıt süresi",
  time_of_upload: "Yükleme süresi",
  lets_setup_event: "Etkinliğinizi birlikte düzenleyelim",
  we_will_help_you_setup: "Sadece 2 dakika içinde everlense etkinliğinizi kurmanıza yardımcı olacağız. Hoşunuza gitti mi?",
  what_is_the_name: "Etkinliğinizin adı ne olmalı?",
  cancel_setup: "Kurulumu iptal et",
  enter_event_name_here: "Etkinlik adını buraya girin",
  when_does_event_take_place: "Etkinliğiniz ne zaman gerçekleşecek?",
  enter_event_date_here: "Etkinlik tarihini buraya girin",
  nice_please_upload_photo: "Çok güzel. Daha da kişiselleştirmek için şimdi bir etkinlik fotoğrafı yükleyin",
  select_photo: "Fotoğraf seçin",
  what_do_you_want_to_describe: "Vay canına! Misafirlerinize söylemek istediğiniz başka bir şey var mı?",
  enter_description_here: "Açıklamayı buraya girin",
  finish_setup: "Kurulumu tamamlayın",
  successfull_save: "Başarıyla kaydedildi",
  successfull_deletion: "Başarıyla silindi",
  copied_link: "Bağlantı kopyalandı",
  booking_successfull: "Rezervasyon başarılı",
  completed_setup: "Kurulum tamamlandı",
  sent_invite: "Davetiye gönderildi",
  initiated_payout: "Ödeme oluşturuldu",
  sent_email: "E-posta gönderildi",
  max_ten_images: "Tek seferde en fazla 10 görüntü.",
  reached_download_limit: "Etkinliğin indirme sınırına ulaşıldı.",
  reached_mass_download_limit: "Maksimum toplu indirme sayısına ulaşıldı.",
  no_images_were_uploaded: "Henüz hiçbir resim yüklenmedi.",
  booking_failed: "Rezervasyon başarısız oldu.",
  package_already_retrieved: "Paket zaten kullanıldı",
  download_all_pictures: "Tüm görselleri indirin",
  notice: "İpucu",
  in_your_package: "Paketinizin içinde",
  bulk_download_modal_2: "tüm fotoğrafların bir kerede. Şimdi \"İndir \"e tıklarsanız şunları tüketeceksiniz",
  bulk_download_modal_3: "bunlardan biri",
  bulk_download_modal_4: "Bu",
  bulk_download_modal_5: "İndir",
  bulk_download_modal_6: "Paketinizdeki tüm toplu indirmeleri kullandınız. Lütfen tüm görselleri indirmek için bir yükseltme rezervasyonu yapın.",
  bulk_download_modal_7: "Lütfen bekleyiniz. Resimleriniz indirilmek üzere hazırlanıyor.",
  donwload_modal_1: '',
  donwload_modal_2: "gerçekten indirdin mi?",
  delete_modal_1: '',
  delete_modal_2: "Gerçekten silmek mi?",
  download_preparation: "İndirme işleminiz hazırlanıyor. En geç 15 dakika içinde kişisel indirme bağlantınızı içeren bir e-posta alacaksınız.",
  save_pictures: "Görüntüleri kaydet",
  delete_pictures: "Görüntüleri sil",
  upload_in_progress: "Yükleme devam ediyor",
  change_language: "Dili değiştir",
  change_language_info: "everlense'i, dilin konuklarınızın cihazlarının tarayıcı diline otomatik olarak uyum sağlayacak şekilde geliştirdik. Yani düğününüzde uluslararası misafirleriniz varsa, bu hiç sorun değil ve fotoğraf galerisi herkes için her zaman anlaşılabilir.",
  multiple_languages_1: "Çok Dillilik",
  multiple_languages_2: "(İngilizce ve Almanca)",
  okay: "Pekala!",
  download_pictures: "Resimleri indirin",
  max_ten_images_go_to_settings: "Maksimum 10 resme izin verilir. Tüm resimleri indirmek için ayarlara gidin.",
  your_images_are_ready: "Yaşasın, fotoğraflarınız hazır!",
  here_are_your_images: "{event}İşte etkinliğinizin resimleri. İyi eğlenceler!",
  ps_mass_download: "Not: Parçaları birbiri ardına indirmeniz yeterlidir. Bu yalnızca toplu indirme olarak sayılacaktır.",
  download_here: "Buradan indirin",
  download_part: "{part} Parçayı indir",
  switch_language: "Dili değiştir",
  tasks_choose_language: "Örnek görevler hangi dile sahip olmalıdır?",
  successfull_task_recreation: "Örnek görevlerin dili değiştirildi",
  something_went_wrong: "Bir şeyler ters gitti",
  a_small_present_for_you: "Size küçük bir hediye!",
  present_description: "Etkinliğinizde everlense kullanmak ister misiniz? <span class=\"font-medium\">O zaman sizin için bir sürprizimiz var</span>. E-posta adresinize göndereceğiz.",
  your_email_address: "E-posta adresiniz",
  save_discount: "Gönderme",
  accept_agb_and_mail: "<a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">Hüküm ve koşulları</a> kabul ediyorum ve everlense <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">'</a> den e-posta almak istiyorum.",
  thank_you_for_sending: "Önümüzdeki 15 dakika içinde size kişiselleştirilmiş sürprizinizi içeren bir e-posta göndereceğiz.",
  discount_saved: "Sürpriz güvence altına alındı!",
  was_reported: "{type} Bir misafir bunu rapor etti.",
  what_shall_happen: "{type} Buna ne olacak?",
  edit_reporting_description: "{type} Lütfen kontrol edin ve tekrar yayınlanıp yayınlanmayacağına veya kalıcı olarak silinip silinmeyeceğine karar verin. Bu arada resmi gizledik.",
  how_did_you_get_to_know_us: "Everlense'i nasıl duydunuz?",
  please_select: "Lütfen seçiniz",
  friends_and_family: "Arkadaşlar & Tanıdıklar",
  seen_at_a_wedding: "Bir düğünde görülmüş",
  other: "Çeşitli",
  settings_deactive_challenge_text_download: "İndirme sırasında görüntüleri etiketleme",
  your_payment_is_processing: "Ödemeniz işleme alınıyor. Lütfen biraz sabırlı olun.",
  coupon_code: "Kupon kodu"
};