export const phrases = {
  your_party: "Το πάρτι σας.",
  all_pictures: "Όλες οι φωτογραφίες.",
  forgot_password: "Ξεχάσατε τον κωδικό πρόσβασής σας;",
  no_account_yet: "Δεν έχετε ακόμη λογαριασμό; Εγγραφείτε τώρα.",
  send_your_event: "Προσκαλέστε τους καλεσμένους σας",
  scan_here: "Σαρώστε εδώ",
  show_more_replies: "δείτε περισσότερες απαντήσεις",
  shared_image_text: "Ρίξτε μια ματιά σε αυτή τη φωτογραφία που τραβήχτηκε σε μια εκδήλωση με το https://www.everlense.de.",
  image_not_available_anymore: "Αυτή η εικόνα δεν είναι πλέον διαθέσιμη",
  add_images: "Προσθήκη εικόνων",
  capture_photo: "Βγάλτε μια φωτογραφία",
  enter_your_name: "Πληκτρολογήστε το όνομά σας",
  your_photo_app_for_weddings: "Η εφαρμογή φωτογραφιών σας για γάμους",
  invite_guests: "Προσκαλέστε καλεσμένους",
  delete_demo_data: "Διαγραφή δεδομένων demo",
  event_description_placeholder: "Τι άλλο θα θέλατε να πείτε στους καλεσμένους σας; Μεταβείτε στις ρυθμίσεις για να προσαρμόσετε την περιγραφή.",
  start_slideshow: "Έναρξη προβολής διαφανειών εδώ",
  slideshow_steps_1: "Πηγαίνετε στο με τη συσκευή σας",
  slideshow_steps_2: "και πληκτρολογήστε τα εξής",
  slideshow_steps_3: "10ψήφιος κωδικός",
  slideshow_steps_4: "σε.",
  print_tasks: "Εργασίες εκτύπωσης",
  edit_tasks: "Διαχείριση εργασιών",
  challenge_tips_and_tricks_1: "Συμβουλές & κόλπα για",
  challenge_tips_and_tricks_2: "Η τέλεια πρόκλησή σας",
  challenge_tips_and_tricks_content_1: "Εκτυπώστε το πρότυπό σας σε χοντρό χαρτί.",
  challenge_tips_and_tricks_content_2: "Διανείμετε τις εργασίες σε κάθε θέση πριν από το πάρτι σας.",
  challenge_tips_and_tricks_content_3: "Ενημερώστε όσο το δυνατόν περισσότερους ανθρώπους για την πρόκληση φωτογραφίας.",
  challenge_tips_and_tricks_content_4: "Χρησιμοποιήστε τη ζωντανή προβολή διαφανειών, ώστε όλοι οι καλεσμένοι να μπορούν να δουν τα στιγμιότυπα σε πραγματικό χρόνο.",
  print_tasks_intro: "Θα πρέπει να τα εκθέσετε στο πάρτι, ώστε οι καλεσμένοι σας να μπορούν να βρουν τα καθήκοντα της φωτογραφικής πρόκλησης.",
  print_tasks_title_1: "Κάρτες για εκτύπωση (δημοφιλείς)",
  print_tasks_title_2: "Εξαγωγή μόνο κωδικών QR (και σχεδιασμός καρτών μόνοι σας)",
  print_tasks_action_1: "Λήψη PDF",
  print_tasks_action_2: "Λήψη κωδικών QR",
  print_tasks_description_1: "Οι καλεσμένοι σας μπορούν να συμμετάσχουν στην πρόκληση φωτογραφίας χρησιμοποιώντας τους κωδικούς QR στις εκτυπώσιμες κάρτες μας.",
  print_tasks_description_2: "Κατεβάστε κωδικούς QR για να ξεκινήσετε την πρόκληση φωτογραφιών και χρησιμοποιήστε τους για να σχεδιάσετε τις δικές σας κάρτες. Υπάρχει ένας κωδικός QR ανά αποστολή.",
  edit_tasks_title_1: "Επιλέξτε τις εργασίες",
  edit_tasks_title_2: "για τους καλεσμένους σας",
  really_want_to_delete_task: "Πραγματικά διαγράφετε την εργασία;",
  add_task_button: "Προσθήκη εργασίας",
  delete_all_tasks_button: "Διαγραφή όλων",
  settings_event_name_header: "Πώς θα πρέπει να ονομάζεται η εκδήλωσή σας;",
  settings_event_description_header: "Τι άλλο πρέπει να γνωρίζουν οι καλεσμένοι σας;",
  settings_event_name_label: "Όνομα της εκδήλωσης",
  settings_event_description_label: "Περιγραφή της εκδήλωσης",
  settings_password_title: "Αλλαγή κωδικού πρόσβασης",
  settings_old_password_header: "Παλαιός κωδικός πρόσβασης",
  settings_new_password_header: "Νέος κωδικός πρόσβασης",
  settings_new_password_repeat_header: "Επαναλάβετε τον νέο κωδικό πρόσβασης",
  settings_invite_admin_title: "Προσθήκη διαχειριστών",
  settings_invite_admin_header: "Διεύθυνση ηλεκτρονικού ταχυδρομείου του νέου διαχειριστή",
  send_invite: "Αποστολή πρόσκλησης",
  settings_deactivate_challenge_title: "Απενεργοποίηση πρόκλησης",
  settings_deactivate_challenge_header: "Ενεργοποίηση/απενεργοποίηση της πρόκλησης",
  settings_delete_data_title: "Διαγραφή λογαριασμού",
  settings_delete_data_header: "Μόνιμη διαγραφή όλων των δεδομένων",
  delete_event: "Διαγραφή συμβάντος",
  delete_event_toast_content: "Κάντε κλικ στο \"Διαγραφή\" για να κλείσετε οριστικά το λογαριασμό σας. ΠΡΟΣΟΧΗ: Όλες οι εικόνες, τα βίντεο και τα δεδομένα που έχουν μεταφορτωθεί χάνονται τότε αμετάκλητα και δεν μπορούν πλέον να αποκατασταθούν ούτε από την ομάδα everlense.",
  settings_legal: "Νομικά θέματα",
  settings_legal_content_1: "Με τη δημιουργία της εκδήλωσής σας έχετε",
  settings_legal_content_2: "GTC",
  settings_legal_content_3: " και το",
  settings_legal_content_4: "Πολιτική απορρήτου ",
  settings_legal_content_5: "αποδεκτή. Αν θέλετε να μάθετε περισσότερα για το everlense, επισκεφθείτε το τμήμα μας ",
  settings_legal_content_6: "Εκτύπωση.",
  reset_password: "Επαναφορά κωδικού πρόσβασης",
  register_phrase: "Χρειαζόμαστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας για να οριστικοποιήσουμε την οργάνωση της εκδήλωσής σας.",
  register_phrase_booked: "Η κράτηση ήταν επιτυχής! Χρειαζόμαστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας για να οριστικοποιήσουμε τη διοργάνωση της εκδήλωσής σας.",
  email_address: "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
  register_legal: "Με την εγγραφή σας, αποδέχεστε <a class=\"underline\" href=\"https://www.everlense.de/agb\">τους όρους και τις προϋποθέσεις</a> μας και την <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">πολιτική απορρήτου</a> μας. Αν θέλετε να μάθετε περισσότερα για την everlense, επισκεφθείτε τη <a class=\"underline\" href=\"https://www.everlense.de/impressum\">νομική</a> μας <a class=\"underline\" href=\"https://www.everlense.de/impressum\">ειδοποίηση</a>. Με την εγγραφή σας, συμφωνείτε επίσης να λαμβάνετε μηνύματα ηλεκτρονικού ταχυδρομείου μάρκετινγκ από εμάς. Μπορείτε να ανακαλέσετε αυτή τη συγκατάθεση ανά πάσα στιγμή.",
  install_everlense: "Εγκαταστήστε το everlense",
  install_content_1: "Εγκαταστήστε το everlense στην αρχική σας οθόνη για να μην ξεχάσετε ποτέ το γεγονός.",
  install_content_2: "Κάντε κλικ στο",
  install_content_3: "και στη συνέχεια \"Μετάβαση στην αρχική οθόνη\".",
  install_content_4: "Αργότερα",
  install_content_5: "Εντάξει!",
  no_images_yet: "Δεν έχουν μεταφορτωθεί ακόμη φωτογραφίες. Πατήστε το κουμπί συν για να ανεβάσετε μια εικόνα.",
  delete_all_tasks_really: "Διαγράφετε πραγματικά όλες τις εργασίες;",
  your_task: "Η αποστολή σας",
  change_colors: "Αλλαγή χρωμάτων",
  primary_color: "Πρωτεύον χρώμα",
  background_color: "Χρώμα φόντου",
  reset: "Επαναφορά",
  choose_color: "Επιλέξτε χρώμα",
  upgrade_package: "Πακέτο αναβάθμισης",
  upgrade_required: "Απαιτείται αναβάθμιση",
  current_package: "Τρέχον πακέτο",
  used_image_count: "{used} {max} των εικόνων.",
  deleted_image_count: "{not_deleted} {deleted} αυτή τη στιγμή στη γκαλερί σας, διαγράφονται.",
  used_downloads_count: "{used} {max} που χρησιμοποιούνται από μαζικές λήψεις.",
  most_popular: "Τα πιο δημοφιλή",
  price: "{price} Ευρώ",
  max_media: "{count} max. εικόνες",
  max_guests: "{count} max. Φιλοξενούμενοι",
  infinite_guests: "Απεριόριστος αριθμός επισκεπτών",
  storage_duration: "{duration} Μήνες Περίοδος αποθήκευσης",
  max_bulk_downloads: "δωρεάν μαζική λήψη | δωρεάν μαζικές λήψεις",
  custom_colors: "Ατομικά χρώματα",
  multiple_admins: "Πολλαπλοί διαχειριστές",
  whatsapp_support: "Υποστήριξη Premium WhatsApp",
  go_to_checkout: "Στο ταμείο",
  start_setup: "Έναρξη εγκατάστασης",
  upgrade_toast_content: "Για να μπορέσετε να χρησιμοποιήσετε αυτή τη λειτουργία, πρέπει να αναβαθμίσετε το πακέτο everlense. Ρίξτε μια ματιά στις διάφορες επιλογές. ",
  no_thank_you: "Όχι, ευχαριστώ.",
  i_want_to_know_more: "Μάθετε περισσότερα",
  demo_event_title: 'Lisa & Jan',
  how_do_you_want_to_reedem: "Πώς θα θέλατε να εξαργυρώσετε το δέμα σας;",
  currently_logged_in_event: "{name} Έχετε συνδεθεί στην εκδήλωση: Θα θέλατε να εφαρμόσετε την αγορά σας σε αυτή την εκδήλωση;",
  choose_event: "Σε ποια εκδήλωση θα θέλατε να χρησιμοποιήσετε την αγορά σας;",
  use_for_current_event: "τρέχον γεγονός",
  use_for_existing_event: "υπάρχον γεγονός",
  use_for_new_event: "νέα εκδήλωση",
  settings_event_date_header: "Πότε θα πραγματοποιηθεί η εκδήλωσή σας;",
  settings_event_date_label: "Ημερομηνία της εκδήλωσης",
  sort: "Ταξινόμηση",
  time_of_recording: "Χρόνος εγγραφής",
  time_of_upload: "Χρόνος φόρτωσης",
  lets_setup_event: "Ας οργανώσουμε μαζί την εκδήλωσή σας",
  we_will_help_you_setup: "Θα σας βοηθήσουμε να δημιουργήσετε την εκδήλωση everlense σε μόλις 2 λεπτά. Σας αρέσει;",
  what_is_the_name: "Ποιο θα πρέπει να είναι το όνομα της εκδήλωσής σας;",
  cancel_setup: "Ακύρωση ρύθμισης",
  enter_event_name_here: "Εισάγετε το όνομα της εκδήλωσης εδώ",
  when_does_event_take_place: "Πότε θα πραγματοποιηθεί η εκδήλωσή σας;",
  enter_event_date_here: "Εισάγετε την ημερομηνία της εκδήλωσης εδώ",
  nice_please_upload_photo: "Πολύ ωραία. Ανεβάστε μια φωτογραφία εκδήλωσης τώρα για να την κάνετε ακόμα πιο εξατομικευμένη",
  select_photo: "Επιλέξτε φωτογραφία",
  what_do_you_want_to_describe: "Ουάου! Κάτι άλλο που θα θέλατε να πείτε στους καλεσμένους σας;",
  enter_description_here: "Εισάγετε την περιγραφή εδώ",
  finish_setup: "Οριστικοποίηση της εγκατάστασης",
  successfull_save: "Αποθηκεύτηκε επιτυχώς",
  successfull_deletion: "Διαγράφηκε επιτυχώς",
  copied_link: "Σύνδεσμος που αντιγράφηκε",
  booking_successfull: "Επιτυχής κράτηση",
  completed_setup: "Η εγκατάσταση ολοκληρώθηκε",
  sent_invite: "Αποστολή πρόσκλησης",
  initiated_payout: "Πληρωμή που δημιουργήθηκε",
  sent_email: "Αποστολή e-mail",
  max_ten_images: "Μέγιστο 10 εικόνες ταυτόχρονα.",
  reached_download_limit: "Το όριο λήψης της εκδήλωσης έχει επιτευχθεί.",
  reached_mass_download_limit: "Έφτασε ο μέγιστος αριθμός μαζικών λήψεων.",
  no_images_were_uploaded: "Δεν έχουν μεταφορτωθεί ακόμη φωτογραφίες.",
  booking_failed: "Η κράτηση απέτυχε.",
  package_already_retrieved: "Πακέτο που έχει ήδη εξαργυρωθεί",
  download_all_pictures: "Κατεβάστε όλες τις εικόνες",
  notice: "Σημείωση",
  in_your_package: "Στο δέμα σας",
  bulk_download_modal_2: "όλων των φωτογραφιών ταυτόχρονα. Αν τώρα κάνετε κλικ στο \"Λήψη\" θα καταναλώσετε",
  bulk_download_modal_3: "ένα από αυτά",
  bulk_download_modal_4: "αυτό το",
  bulk_download_modal_5: "λήψη",
  bulk_download_modal_6: "Έχετε εξαντλήσει όλες τις μαζικές λήψεις στο πακέτο σας. Παρακαλούμε κάντε κράτηση αναβάθμισης για να κατεβάσετε όλες τις εικόνες.",
  bulk_download_modal_7: "Παρακαλώ περιμένετε. Οι εικόνες σας προετοιμάζονται για λήψη.",
  donwload_modal_1: '',
  donwload_modal_2: "πραγματικά κατεβάζετε;",
  delete_modal_1: '',
  delete_modal_2: "Αλήθεια διαγράφετε;",
  download_preparation: "Η λήψη σας ετοιμάζεται. Θα λάβετε ένα e-mail με τον προσωπικό σας σύνδεσμο λήψης το αργότερο σε 15 λεπτά.",
  save_pictures: "Αποθήκευση εικόνων",
  delete_pictures: "Διαγραφή εικόνων",
  upload_in_progress: "Ανέβασμα τρέχοντος",
  change_language: "Αλλαγή γλώσσας",
  change_language_info: "Έχουμε αναπτύξει το everlense έτσι ώστε η γλώσσα να προσαρμόζεται αυτόματα στη γλώσσα του προγράμματος περιήγησης των συσκευών των επισκεπτών σας. Έτσι, αν έχετε διεθνείς καλεσμένους στο γάμο σας, αυτό δεν αποτελεί πρόβλημα και η συλλογή φωτογραφιών είναι πάντα κατανοητή για όλους.",
  multiple_languages_1: "Πολυγλωσσία",
  multiple_languages_2: "(Αγγλικά & Γερμανικά)",
  okay: "Εντάξει!",
  download_pictures: "Λήψη εικόνων",
  max_ten_images_go_to_settings: "Επιτρέπονται το πολύ 10 εικόνες. Μεταβείτε στις ρυθμίσεις για να κατεβάσετε όλες τις εικόνες.",
  your_images_are_ready: "Ναι, οι φωτογραφίες σας είναι έτοιμες!",
  here_are_your_images: "{event}Εδώ είναι οι φωτογραφίες της εκδήλωσής σας . Καλή διασκέδαση!",
  ps_mass_download: "ΥΓ: Απλά κατεβάστε τα μέρη το ένα μετά το άλλο. Αυτό θα μετρήσει μόνο ως μαζική λήψη.",
  download_here: "Κατεβάστε εδώ",
  download_part: "{part} Λήψη μέρους",
  switch_language: "Αλλαγή γλώσσας",
  tasks_choose_language: "Ποια γλώσσα πρέπει να έχουν οι δειγματικές εργασίες;",
  successfull_task_recreation: "Η γλώσσα των εργασιών του παραδείγματος έχει αλλάξει",
  something_went_wrong: "Κάτι έχει πάει στραβά",
  a_small_present_for_you: "Ένα μικρό δώρο για εσάς!",
  present_description: "Θα θέλατε να χρησιμοποιήσετε το everlense στην εκδήλωσή σας; <span class=\"font-medium\">Τότε σας έχουμε μια έκπληξη</span>. Θα το στείλουμε στη διεύθυνση ηλεκτρονικού ταχυδρομείου σας.",
  your_email_address: "Η διεύθυνση ηλεκτρονικού ταχυδρομείου σας",
  save_discount: "Αποστολή",
  accept_agb_and_mail: "Συμφωνώ με τους <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">όρους και τις προϋποθέσεις</a> και επιθυμώ να λαμβάνω μηνύματα ηλεκτρονικού ταχυδρομείου από την everlense.",
  thank_you_for_sending: "Θα σας στείλουμε ένα e-mail με την προσωπική σας έκπληξη μέσα στα επόμενα 15 λεπτά.",
  discount_saved: "Έκπληξη εξασφαλισμένη!",
  was_reported: "{type} Ένας επισκέπτης ανέφερε αυτό .",
  what_shall_happen: "{type} Τι πρέπει να συμβεί σε αυτό;",
  edit_reporting_description: "{type} Παρακαλούμε ελέγξτε και αποφασίστε αν θα πρέπει να κυκλοφορήσει ξανά ή να διαγραφεί οριστικά. Εν τω μεταξύ, κρύψαμε την εικόνα.",
  how_did_you_get_to_know_us: "Πώς μάθατε για την everlense;",
  please_select: "Παρακαλώ επιλέξτε",
  friends_and_family: "Φίλοι & γνωστοί",
  seen_at_a_wedding: "Σε γάμο",
  other: "Διάφορα",
  settings_deactive_challenge_text_download: "Επισήμανση εικόνων κατά τη λήψη",
  your_payment_is_processing: "Η πληρωμή σας επεξεργάζεται. Παρακαλούμε να είστε υπομονετικοί για ένα λεπτό.",
  coupon_code: "Κωδικός κουπονιού"
};